import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Exposed 20 ga Epicore MSR as Loft Ceiling' },
    { img: data.i2, caption: 'Exposed 20 ga Epicore MSR as Loft Ceiling' },
    { img: data.i3, caption: 'Exposed 22 ga Epicore MSR as Loft' },
    { img: data.i4, caption: 'Exposed 22 ga Epicore MSR as Loft' },
    { img: data.i5, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i6, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i7, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i8, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i9, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i10, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i11, caption: 'Epicore MSR 20 ga Painted Close-Up' },
    { img: data.i12, caption: 'Hat Channels under Epicore MSR' },
    { img: data.i13, caption: 'Hat Channels under Epicore MSR' },
    { img: data.i14, caption: 'Soffit Framing Along Side of Room' },
    { img: data.i15, caption: 'Suspended Drywall Ceiling' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: Ceiling Treatments" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>Ceiling Treatments</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>The Infinity Structural System offers the Owner & Architect several finished ceiling
            options. You can leave the underside of the Epicore MSR deck exposed galvanized for
            a chic urban loft look. You can paint the Epicore MSR deck to create a linear plank
            “Tongue &  Groove” appearance. Or you can go with a traditional drywall ceiling.</p>
            <p className='type--lg'>
              <Link className="link-next" to="system-overview/demising-walls/">
                Next Page: Demising Walls / Tenant Separation Walls
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="system-overview/cantilevers/">
                Prev Page: Cantilevers
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/01-CEILI260_(34288).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/02-CEILI270_(34278).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/03-CEILI450_(40585).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i4: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/04-CEILI460_(40586).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i5: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/05-CEILI110_(40010).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i6: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/06-CEILI030_(41626).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i7: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/07-Mason110_(41957).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i8: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/08-CEILI570_(42004).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i9: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/09-CEILI580_(42005).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i10: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/10-CEILI510_(40655).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i11: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/11-CEILI540_(40658).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i12: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/12-CEILI330_(34246).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i13: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/13-CEILI410_(40273).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i14: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/14-CEILI560_(42869).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i15: file(relativePath: { eq: "system-overview/ceiling-treatments/gallery/15-CEILI440_(40690).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
